@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Outfit';
  src: url('../public/fonts/Outfit.woff2') format('woff2'),
       url('../public/fonts/Outfit.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit';
  src: url('../public/fonts/Outfit-SemiBold.ttf') format('truetype');
  font-weight: 600; /* SemiBold weight */
  font-style: normal;
}

@font-face {
  font-family: 'Outfit';
  src: url('../public/fonts/Outfit-Black.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}


html, body {
  font-family: 'Outfit', sans-serif;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hamburger Icon */
.icon-menu {
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  position: relative;
}

.bar {
  width: 30px;
  height: 2.5px; /* Keep the thickness */
  /* background-color: #38b2ac; */
  border-radius: 0.5rem;
  transition: transform 0.2s, opacity 0.2s, top 0.2s, bottom 0.2s;
  transform-origin: center; /* Ensure the rotation happens from the center */
}

/* First bar */
.icon-menu .bar--1 {
  top: 0;
  position: relative;
}

/* Middle bar */
.icon-menu .bar--2 {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

/* Third bar */
.icon-menu .bar--3 {
  position: relative;
  bottom: 0;
}

/* Animation when menu is open */
.icon-menu.open .bar--1 {
  transform: rotate(45deg) translateY(5px) translateX(5px); /* No need to adjust translateY if transform-origin is centered */
}

.icon-menu.open .bar--2 {
  opacity: 0;
}

.icon-menu.open .bar--3 {
  transform: rotate(-45deg) translateY(-5px) translateX(5px); /* Same as above */
}


/* Menu Sliding in From the Right */
.menu {
  position: fixed; /* Stays relative to the viewport */
  right: 0;
  top: 4.6rem; /* Ensure it’s just below the navbar */
  height: calc(100vh - 4rem); /* Take full height minus navbar */
  width: 60%;
  background-color: inherit; /* Adapts based on light/dark mode */
  transform: translateX(100%); /* Offscreen initially */
  transition: transform 0.3s ease-in-out; /* Smooth sliding effect */
}

.menu.open {
  transform: translateX(0); /* Slide in when opened */
}